<template>
    <div class="container my-5">
        <div class="h4 mb-4">Client Requests for material testing</div>
        <template v-for="(request, i) in requests">
            <RequestItem :key="request.id" :request="request" @update="updateRequest($event, i)" />
        </template>
        <div v-if="requests.length === 0">No pending requests.</div>
    </div>
</template>

<script>
import RequestItem from "./RequestItem.vue"
import { mapGetters, mapState } from "vuex"
import request from "../../apis/request"
import scrollTop from "../utils/scrollTop"

export default {
    components: {
        RequestItem,
    },
    data() {
        return {
            requests: [],
        }
    },
    async mounted() {
        if (!this.isMaterialTester) {
            return this.$router.push({ name: "dashboard" })
        }
        scrollTop()
        await this.fetchRequests()
    },
    methods: {
        async fetchRequests() {
            const res = await request.get("/material-tester-requests")
            const data = res.data
            this.requests = data.result
        },
        updateRequest(newRequest, i) {
            const requests = [...this.requests]
            // for now update only accepted or rejected status...
            console.log("update request running", newRequest)
            requests[i].accepted = newRequest.accepted
            requests[i].rejected = newRequest.rejected
            requests[i].ticket_id = newRequest.ticket_id
            this.requests = requests
        },
    },
    computed: {
        ...mapState("auth", {
            user: "user",
        }),
        ...mapGetters("auth", {
            isMaterialTester: "isMaterialTester",
        }),
    },
}
</script>

<style></style>

<template>
    <div :class="['request-container', { active: showDetails }]">
        <div class="request-item-header">
            <div class="request-title-container d-flex align-items-center">
                <div class="h5">
                    <span class="text-capitalize">{{ request.user.name }}</span
                    >'s request for inspection.
                </div>
            </div>
            <div class="request-btn-container">
                <button class="primary-button view-details-btn" @click="viewDetails">
                    {{ showDetails ? "Hide" : "View" }} Details
                </button>
                <button
                    class="btn btn-primary mx-sm-2 custom-btn-config"
                    @click="acceptRequest"
                    :disabled="!!request.rejected || !!request.accepted"
                >
                    {{ request.accepted ? "Accepted " : "Accept" }}
                </button>
                <button
                    class="btn btn-danger custom-btn-config"
                    @click="rejectRequest"
                    :disabled="!!request.accepted || !!request.rejected"
                    v-if="!request.accepted"
                >
                    {{ request.rejected ? "Rejected " : "Reject" }}
                </button>
                <button
                    class="btn btn-warning"
                    @click="
                        $router.push({ name: 'enquire_details', params: { id: request.ticket_id } })
                    "
                    v-else
                >
                    Message
                </button>
            </div>
        </div>
        <!-- <transition name="fade"> -->
        <div v-if="showDetails && request.product">
            <hr />
            <div class="mb-3">Product Details</div>
            <div class="row">
                <div class="col-md-6">
                    <img :src="imgsrc + request.product.image" class="img-fluid" />
                </div>
                <div class="col-md-6 mt-3 mt-md-0">
                    <div class="">
                        Name:
                        <div>{{ request.product.title }}</div>
                    </div>
                    <hr />
                    <div>
                        Description:
                        <div>{{ request.product.description }}</div>
                    </div>
                    <hr />
                    <div>
                        Price:
                        <div>₹ {{ request.product.price }}</div>
                    </div>
                    <button class="primary-button" @click="openProductPage">
                        View More <i class="fa fa-external-link mx-2" />
                    </button>
                </div>
            </div>
        </div>
        <!-- </transition> -->
    </div>
</template>

<script>
import axios from "axios"
import request from "../../apis/request"

export default {
    props: {
        request: {
            type: Object,
        },
    },
    data() {
        return {
            showDetails: false,
            imgsrc: axios.defaults.uploadURL,
        }
    },
    methods: {
        async acceptRequest() {
            try {
                const res = await request.post(
                    "/material-tester-requests/accept/" + this.request.id
                )
                const data = res.data
                this.emitUpdate(data.result)
                this.$toasted.success(data.message)
            } catch (err) {
                this.$toasted.error(err.data.response.message)
            }
        },
        async rejectRequest() {
            try {
                const res = await request.post(
                    "/material-tester-requests/reject/" + this.request.id
                )
                const data = res.data
                this.emitUpdate(data.result)
                this.$toasted.success(data.message)
            } catch (err) {
                this.$toasted.error(err.data.response.message)
            }
        },
        viewDetails() {
            this.showDetails = !this.showDetails
        },
        openProductPage() {
            const link = this.$router.resolve({
                name: "single-product",
                params: {
                    id: this.request.product.id,
                },
            }).href
            window.open(link)
        },
        emitUpdate(newRequest) {
            this.$emit("update", newRequest)
        },
    },
}
</script>

<style lang="scss" scoped>
.request-container {
    padding: 25px;
    border-radius: 10px;
    max-width: 800px;
    background: rgb(227, 227, 227);
    margin: 15px 0;
    border: 1px solid transparent;

    &.active {
        background: rgb(244, 245, 222);
        border: 1px solid orange;
    }

    .request-item-header {
        display: flex;

        .request-title-container {
            flex: 1;
        }
    }

    .view-details-btn {
        min-width: 0;
        display: inline;
        padding: 8px;
        margin: 0;
        font-size: 0.9em;
        border-radius: 5px;
    }
    .custom-btn-config {
        box-shadow: none !important;
    }
}

@media screen and (max-width: 468px) {
    .request-container {
        .request-item-header {
            flex-direction: column;

            .request-title-container {
                flex: 1;
            }
            .view-details-btn {
                margin: 10px 0;
                margin-top: 20px;
                flex: 1 2000px;
            }
            .request-btn-container {
                display: flex;
                flex-flow: row wrap;

                button:not(.view-details-btn) {
                    flex: 1;
                }
                button:nth-of-type(2) {
                    margin-right: 5px;
                }
                button:nth-of-type(3) {
                    margin-left: 5px;
                }
            }
        }
    }
}

/* TRANSITIONS */

.fade-enter,
.fade-leave-to {
    opacity: 0;
    transition: 2s;
}
.fade-enter-active,
.fade-leave-active {
    opacity: 1;
}
</style>
